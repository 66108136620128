import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { H2 } from '@styles/Typography'
import Columns from '@blocks/Columns'
import Column from '@blocks/Column'
import { ButtonStyles } from '@mixins/MixinsButton'
import ArrowDown from '@svgs/ArrowDown'
import Heading from '@elements/Heading'
import { motion, AnimatePresence } from 'framer-motion'

export const CommunityPastCharities = styled.div`
  ${box({
    spacing: 'bottom',
  })};
  border-bottom: ${tokens.get('border')};

  .header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin-bottom: 0;
    }
  }

  .toggle {
    ${ButtonStyles}
    width: 8rem;
    margin-left: ${spacing(2)};
    justify-content: space-between;

    svg {
      width: ${spacing(3)};
    }
  }

  .columns {
    ${box({
      spacing: 'top',
      multiplier: 0.5,
    })};
    display: grid;
  }
`

export default ({ heading = 'Past Charities of the Month', charities }) => {
  const [isGridOpen, setGridOpen] = useState(false)
  const toggleGrid = () => {
    setGridOpen(!isGridOpen)
  }

  return (
    <CommunityPastCharities>
      <div className="header">
        <Heading heading={heading} />
        <button type="button" onClick={toggleGrid} className="toggle">
          {isGridOpen ? 'Close' : 'Open'}
          <ArrowDown
            className="arrow-down"
            direction={isGridOpen ? 'up' : 'down'}
          />
        </button>
      </div>
      <AnimatePresence initial={false}>
        {isGridOpen && (
          <motion.div
            className="charities"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: {
                opacity: 1,
                height: 'auto',
                transition: {
                  duration: 0.4,
                  delayChildren: 0.2,
                  staggerChildren: 0.05,
                },
              },
              collapsed: {
                opacity: 0,
                height: 0,
                transition: {
                  duration: 0.2,
                  when: 'afterChildren',
                },
              },
            }}
          >
            <Columns className="columns">
              {charities &&
                charities.map(
                  (
                    {
                      title,
                      contentCharity: { excerpt: orgExcerpt, charityUrl, logo },
                    },
                    index
                  ) => {
                    const excerpt = orgExcerpt
                      ? orgExcerpt.replace(/(<([^>]+)>)/gi, '')
                      : null

                    return (
                      <motion.div
                        key={index}
                        variants={{
                          open: {
                            opacity: 1,
                            y: 0,
                            transition: {
                              duration: 0.4,
                            },
                          },
                          collapsed: {
                            opacity: 0,
                            y: 50,
                            transition: {
                              duration: 0.4,
                            },
                          },
                        }}
                      >
                        <Column
                          heading={title}
                          // content={excerpt}
                          url={charityUrl}
                          image={logo}
                          isLogo
                        />
                      </motion.div>
                    )
                  }
                )}
            </Columns>
          </motion.div>
        )}
      </AnimatePresence>
    </CommunityPastCharities>
  )
}
