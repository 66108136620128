import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import Columns from '@blocks/Columns'
import Column from '@blocks/Column'
import ComponentContent from '@blocks/ComponentContent'

export const CommunityFriends = styled.div``

export default ({ textColumns: { textContent, columns } }) => {
  return (
    <CommunityFriends>
      <ComponentContent
        textContent={textContent}
        headingSize="large"
        contentAlign="left"
      />
      <Columns className="columns" short>
        {columns &&
          columns.map(({ ...column }, index) => {
            return <Column key={index} {...column} />
          })}
      </Columns>
    </CommunityFriends>
  )
}
