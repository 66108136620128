import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Container from '@layout/Container'
import { formatTemplateData } from '@utils/templates'
import ComponentContainer from '@layout/ComponentContainer'
import CommunityFeaturedCharity from '@components/Community/CommunityFeaturedCharity'
import CommunityPastCharities from '@components/Community/CommunityPastCharities'
import CommunityFriends from '@components/Community/CommunityFriends'
import { ComponentSpacer } from '@layout/FlexComponents'

const CommunityPage = ({ data }) => {
  const {
    wpPage: pageData,
    allWpCharity: {
      nodes: [featuredCharityData, ...charityData],
    },
  } = data

  const {
    templateCommunity: { textColumns, charityHeading },
    seo,
    uri,
  } = pageData
  seo.path = uri
  const template = {
    title: pageData.title,
    designations: 'isCommunityPage',
    heroType: 'small',
    theme: 'light',
    seo,
    heroFields: pageData.templateCommunity.heroContent,
    ...pageData.templateCommunity,
  }

  return (
    <Container template={formatTemplateData(template)}>
      <ComponentSpacer />
      <ComponentContainer size="wide" isFlex>
        <CommunityFeaturedCharity
          charity={featuredCharityData}
          heading={charityHeading}
        />
      </ComponentContainer>
      <ComponentContainer size="wide">
        <CommunityPastCharities charities={charityData} />
      </ComponentContainer>
      <ComponentContainer size="wide">
        <CommunityFriends textColumns={textColumns} />
      </ComponentContainer>
    </Container>
  )
}

CommunityPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const CommunityPageQuery = graphql`
  query CommunityPage($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      uri
      templateCommunity {
        charityHeading
        heroContent {
          textContent {
            selectedFields
            heading
            content
            link {
              type
              text
              data {
                title
                url
                target
              }
            }
          }
          image {
            localFile {
              childImageSharp {
                # TODO: Alter sizing
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        textColumns {
          textContent {
            selectedFields
            heading
            content
            link {
              type
              text
              data {
                title
                url
                target
              }
            }
          }
          columns {
            content
            heading
            url
            selectedfields
          }
        }
      }
      seo {
        title
        twitterTitle
        twitterDescription
        twitterImage {
          description
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphTitle
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphDescription
        metaRobotsNoindex
        metaRobotsNofollow
        metaDesc
      }
    }
    allWpCharity(limit: 13, sort: { order: DESC, fields: date }) {
      nodes {
        id
        title
        contentCharity {
          charityUrl
          content
          excerpt
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          logo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          relatedPost {
            ... on WpPost {
              ...TeaserPostFields
            }
          }
        }
      }
    }
  }
`

export default CommunityPage
