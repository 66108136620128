import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { BlogH1Sizes, H2, H3 } from '@styles/Typography'
import TextContent from '@elements/TextContent'
import HeadingWithMedia from '@blocks/HeadingWithMedia'
import Teaser from '@components/Teasers/'
import { formatPostData } from '@utils/templates'
import { TextUnderLine } from '@mixins/MixinsPage'

export const CommunityFeaturedCharity = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .image-title {
    width: 100%;

    .heading {
      ${BlogH1Sizes}
      position: relative;
      z-index: ${tokens.z('aboveContent')};
      max-width: ${tokens.get('maxWidth.narrow')};
      margin-bottom: -0.5em;
    }
  }

  .inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    ${media.greaterThan('m')`
      flex-flow: row nowrap;
    `}
  }

  .content {
    ${box({
      spacing: 'top',
      multiplier: 0.5,
    })};
    max-width: ${styledMap('hasPost', {
      true: tokens.get('maxWidth.tiny'),
      default: tokens.get('maxWidth.base'),
    })};
    margin-left: auto;
    margin-right: auto;

    ${media.greaterThan('m')`
      padding-top: ${spacing(8)};
    `}

    > a {
      ${TextUnderLine}
      margin-bottom: ${spacing(3)};
    }
  }

  .posts {
    ${box({
      spacing: 'top',
      multiplier: 0.5,
      type: 'margin',
    })};
    position: relative;
    z-index: ${tokens.z('aboveContent')};
    flex: 0 0 auto;
    margin-right: 0;
    margin-right: auto;
    margin-left: auto;
    width: 100%;

    ${media.greaterThan('s')`
      width: 19rem;
    `}

    ${media.greaterThan('m')`
      margin-top: ${spacing(-6)};
      margin-right: 0;
      margin-left: 0;
    `}
  }
`

export default ({
  heading = 'Charity of the&nbsp;Month',
  charity: {
    title,
    contentCharity: { content, image, charityUrl, relatedPost },
  },
}) => {
  return (
    <CommunityFeaturedCharity hasPost={!!relatedPost}>
      <HeadingWithMedia
        heading={heading}
        media={{ mediaType: 'image', image }}
        gradient="top"
      />
      <div className="inner">
        <div className="content">
          {title && (
            <>
              {charityUrl ? (
                <a href={charityUrl} target="_blank" rel="noopener noreferrer">
                  <H3
                    textCase="none"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </a>
              ) : (
                <H3
                  textCase="none"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}
            </>
          )}
          {content && (
            <TextContent
              lineHeight="tall"
              size={!!relatedPost ? 'tiny' : 'content'}
              className="summary"
              content={content}
            />
          )}
        </div>
        {relatedPost && (
          <div className="posts">
            {formatPostData(relatedPost).map(
              (
                { id, uri, color, category, title: postTitle, eventDate },
                index
              ) => {
                return (
                  <Teaser
                    key={id}
                    uri={uri}
                    color={color}
                    category={category}
                    title={postTitle}
                    date={eventDate}
                  />
                )
              }
            )}
          </div>
        )}
      </div>
    </CommunityFeaturedCharity>
  )
}
